import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"

import styles from "../../jss/intro.js"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
  sectionDiv: {
    padding: "60px 0px 60px 0px",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" className={classes.sectionDiv}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            {" "}
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            Atlas Agency is an SEO and web design company with years of
            experience. We have built many contractor websites so we already
            have the necessary expertise in your field. Our team’s main goal is
            to increase your conversion by implementing modern design solutions.
            We provide safe, reliable, and user-friendly hosting platforms
            including SquareSpace and Custom React.{" "}
          </p>
          <p className={classes.description}>
            Whether you are an independent electrical contractor or run a large
            company, we can help you convert online visitors to regular clients.
            Don’t miss the opportunity to work with one of the best web design
            companies in Toronto. We’d love to see your electrical website grow
            and get the conversion it deserves!{" "}
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
