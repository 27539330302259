import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import CloudDoneIcon from "@material-ui/icons/CloudDone"

// core components

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"
import styles from "../../jss/intro.js"
import CodeIcon from "@material-ui/icons/Code"
import CreateIcon from "@material-ui/icons/Create"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section} style={{ padding: "60px 0px 60px 0px" }}>
      <Grid container justify="center">
        <Grid item xs={10} sm={12} md={8} lg={8} align="center">
          <h1 className={classes.title}>Electrical Contractor Web Design </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={11}
          align="center"
          style={{ padding: "40px 0px 40px 0px" }}
        >
          <p className={classes.description}>
            Today, the best way to reach potential customers is through a strong
            online presence. Majority of people search for a local business
            online. That’s why almost every industry, including construction, is
            shifting toward digital marketing strategies. Whether you are an
            independent electrical contractor or run a larger company, your
            website is one of your most important assets.{" "}
          </p>
          <p className={classes.description}>
            At Atlas Agency, we have extensive experience in providing SEO & web
            design services. Our team is proud to offer website design for
            electrical contractors. We have helped many contractor websites
            increase online presence and convert visitors into paying clients.{" "}
          </p>
        </Grid>
      </Grid>
      <div style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Custom Made Websites"
              description="We provide resources that will make your website stand out from competitors. We don't use website templates and create everything from scratch. Our custom website approach provides electrical contractors with professional websites."
              icon={CreateIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Hosting"
              description="We ensure that your website runs 24/7 and is available to online visitors at all times. Customer service becomes a thousand times easier with our modern hosting platforms. We can also help in securing your desired domain and connect it to your site."
              icon={CloudDoneIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Mobile Optimization"
              description="If your website isn’t optimized for mobile usage, you can lose potential clients. Atlas Agency creates websites that are user-friendly for mobile, tablet, and desktop usage. Use our services to reach more potential clients. "
              icon={CodeIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
